import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import 'jwt-decode';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../services/api';
import translations from '../translations.json';
import checkSession from '../../utils/checkSession';
  
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';

import { BlobProvider } from '@react-pdf/renderer';
import { MyDocument } from '../pdf/pdf.js';

import axios from 'axios';

import MyEmail from './Email'
import { renderEmail } from 'react-html-email'

export default function Insts() {
	var session = checkSession();
	if(session.state === 3){
		window.location.href = '/profile';
	}else{
		if (!session.permissions[3]['permission_type'][0]['permission'][0].hasPermission) {
			window.location.href = '/dashboard';
		}
	}
	// converts blob to base64
	var blobToBase64 = function(blob, cb) {
		var reader = new FileReader();
		reader.onload = function() {
			var dataUrl = reader.result;
			var base64 = dataUrl.split(',')[1];
			cb(base64);
		};
		reader.readAsDataURL(blob);
	};
	
	async function sendFile(blob,id) {
		blobToBase64(blob, async function(base64){ // encode
			await axios.post('https://cig.alentapp.pt:3333/req', {
				'blob': base64,
				'id': id
			})
			.then(res => {
				handleSendMail();
			});
		})
	}

	// TABLE PEDIDOS
	const type = session.type;
	const [id, setId] = useState(session.id);
	const [forwardings, setForwardings] = useState([]);
	async function loadSubData(idinst) {
		setReload(true);
		console.log("type: ", type)
		if(type === 4){
			//console.log(session);
			// let inst = [];
			// let insts = [];
			// try {
			// 	await api.get('/inst')
			// 	.then(response => {
			// 		insts = response.data;
			// 		inst = response.data.find(x => x.id === session.idinst);
			// 	});
			// 	setInsts(insts);
			// } catch (err) {
			// 	setErrorMessage(err.message);
			// 	setSweetAlertError(true);
			// }
			try {
				// GET LIST FORWARDINGS
				await api.get(`/inst-forwardings/${idinst}/0`)
				.then(response => {
					setForwardings(response.data)
				});
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}

	const tableColumnsForwarding = [
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].id,
			accessor: "idRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].user,
			accessor: "usernNameRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].nvacancies,
			accessor: "vagasRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].type,
			accessor: "tipoRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].dt,
			accessor: "dtRequest"
		},
		{
			Header: translations[session.language]['insts']['panel']['body']['table4'].state,
			accessor: "validateRequestText"
		},
		{
			id: 'button',
			Header: translations[session.language]['insts']['panel']['body']['table4'].options,
			width: 200,
			Cell: ({original}) => (
				<center>
					{
						original.validateRequest === 0 &&
						<button className='btn btn-danger btn-sm ml-1' onClick={() =>{setSweetAlertWarningForwardingRefuse([true, original]);}} title={`${translations[session.language]['insts']['panel']['body']['table4'].buttons.cancel}`}><i className="fa fa-trash"></i></button> 
					}
				</center>
			)
		}
	];

	const [observationRefuse, setObservationRefuse] = useState('')
	const [sweetAlertWarningForwardingRefuse, setSweetAlertWarningForwardingRefuse] = useState([false, 0]);
	async function handleRefuseForwarding(data) {
		console.log(data)
		try {
			await api.put(`/inst-forwarding/${data.idInst}/${data.idRequest}/${session.id}/3`, {observation: observationRefuse})
			.then(async (response) => {
				setSuccessMessage(translations[session.language]['insts']['responses']['cancelrequest'].success);
				setSweetAlertSuccess(true);
				await loadData()
			})
			.catch(error => {
				if(error.response.status === 422) {
					setErrorMessage(translations[session.language]['insts']['responses']['cancelrequest'][error.response.data.code]);
				    setSweetAlertError(true);
				}else{
					setErrorMessage('ERROR');
					setSweetAlertError(true);
				}
			});
		} catch (err) {
			setErrorMessage('ERROR');
			setSweetAlertError(true);
		}
	}
	const defaultSortedDesc = [
		{
			id: "dt",
			desc: true
		}
	];
  
	const Download = () => (
		<div>  {pagePdf != null &&
				<BlobProvider document={<MyDocument data={[useId, {}, pagePdf]} />}>
				{({ blob, url, loading }) => (
					loading ? 
					// eslint-disable-next-line
					<a className="btn btn-primary ml-1" style={{fontSize: 10, padding: 5, margin: -5, visibility: 'hidden'}}>
						<i className="fa fa-file-alt"></i>
						&nbsp; {translations[session.language]['entity']['panelinf1']['body']["download"]}
					</a> : 
					<button id='autoDownload' onClick={() => sendFile(blob,useId)} className="btn btn-primary ml-1" style={{fontSize: 10, padding: 5, margin: -5, visibility: 'hidden'}}>
						<i className="fa fa-file-alt"></i>
						&nbsp; {translations[session.language]['entity']['panelinf1']['body']["download"]}
					</button>
					/*<a id='autoDownload' href={url} onClick={openPdf(url)} target='_blank' rel='noopener noreferrer' className="btn btn-primary ml-1" style={{fontSize: 10, padding: 5, margin: -5, visibility: 'hidden'}}>
						<i className="fa fa-file-alt"></i>
						&nbsp; {translations[session.language]['entity']['panelinf1']['body']["download"]}
					</a>*/
					
				)}
				</BlobProvider>
			}
		</div>
	)
	function eventFire(el, etype, idele){
		if (typeof(el) != 'undefined' && el != null){
			if (el.fireEvent) {
				el.fireEvent('on' + etype);
			} else {
				var evObj = document.createEvent('Events');
				evObj.initEvent(etype, true, false);
				el.dispatchEvent(evObj);
			}
		}else{
			setTimeout(function(){ eventFire(document.getElementById(idele), etype, idele) },500);
		}
	}

	function openPdf(url){
		window.open(url, "_blank");
		setPagePdf(null);
	}

	// function loopEventFire(el, etype){
	// 	if (typeof(el) != 'undefined' && el != null){
	// 		eventFire(el, etype);
	// 	}else{
	// 		setTimeout(function(){ loopEventFire(el, etype) },1000);
	// 	}
	// }
	function handleSendMail(){
		console.log(req);	
		fetch('https://www.alentapp.pt/ok4alentapp/uploads/php/sendmail.php?id='+useId, { // URL
		    body: JSON.stringify({name:'teste',email:'teste',phone:'teste',dt:new Date().toISOString().replace('T',' ').split('.')[0]}), // data you send.
		    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		    headers: {
		      'content-type': 'application/x-www-form-urlencoded'
		    },
		    method: 'POST', // *GET, POST, PUT, DELETE, etc.
		    mode: 'no-cors', // no-cors, cors, *same-origin
		    redirect: 'follow', // *manual, follow, error
		    referrer: 'no-referrer', // *client, no-referrer
		})
		.then(function(response) {
		    // manipulate response object
		    // check status @ response.status etc.
		    console.log(response); // parses json
		})
		.then(function(myJson) {
		    // use parseed result
		    console.log(myJson);
		});
    }
	// STATE FORMAT TABLE -> PDF
	const [ pagePdf, setPagePdf ] = useState(null);
	const [ useId, setUseId ] = useState(5);
    //const [ pageRtm, setPageRtm ] = useState([{"op_number":"","created_at":"","obs":"","name":"","reference":""}]);
	
	const [modalDialog, setModalDialog] = useState(false);

	const [sweetAlertSuccess, setSweetAlertSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const [sweetAlertWarning, setSweetAlertWarning] = useState(false);

	const [sweetAlertError, setSweetAlertError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const [insts, setInsts] = useState([]);
	const [instsFiltered, setInstsFiltered] = useState([]);

	const [agregado, setAgregado] = useState([]);
	const [agressor, setAgressor] = useState([]);

	const [currentVacancyA, setCurrentVacancyA] = useState(0);
	const [currentVacancyA_Ra, setCurrentVacancyA_Ra] = useState(0);
	const [currentVacancyE, setCurrentVacancyE] = useState(0);
	async function toggleModal(idmodal) {
		await setModalDialog(!modalDialog);
		if(idmodal !== 0){
			const inst = insts.find(x => x.id === idmodal);
			document.getElementById('nameInst').innerHTML = inst.name;
			document.getElementById('emailInst').innerHTML = inst.email;
			document.getElementById('addressInst').innerHTML = inst.address;
			document.getElementById('postalcodeInst').innerHTML = inst.postalcode;
			document.getElementById('cityInst').innerHTML = inst.city;
			document.getElementById('districtInst').innerHTML = inst.district;
			document.getElementById('phoneInst').innerHTML = inst.phone;
		}
	}

	const [expand, setExpand] = useState(false);
	const [reload, setReload] = useState(false);
	//const [reloadSub, setReloadSub] = useState(false);

	const [isMain, setIsMain] = useState(true);

	const [selectedInsts, setSelectedInsts] = useState([]);

	function toggleExpand() {
		setExpand(!expand);
	}

	const defaultSorted = [
		{
			id: "district",
			desc: false
		}
	];
	const tableColumns = [
		{
			Header: translations[session.language]['entity']['panel']['body']['table'].district,
			accessor: "district"
		},
		{
			Header: translations[session.language]['entity']['panel']['body']['table'].name,
			accessor: "name"
		},
		{
			Header: translations[session.language]['entity']['panel']['body']['table'].vacancya,
			accessor: "lastvacancya"
		},
		{
			Header: translations[session.language]['entity']['panel']['body']['table'].vacancye,
			accessor: "lastvacancye"
		},
		{
			Header: translations[session.language]['entity']['panel']['body']['table'].dt,
			accessor: "lastdt"
		},
		{
			id: 'button',
			Header: translations[session.language]['entity']['panel']['body']['table'].options,
			accessor: 'extra',
			width: 200,
			Cell: ({value}) => (
				<center>
					{(value.showinfo === 1
					? <button className='btn btn-info btn-sm ml-1' onClick={() => toggleModal(value.id)} title={translations[session.language]['entity']['panel']['body']['table'].buttons.see}><i className="fa fa-search"></i></button>
						
					: '')}
					{(session.permissions[3]['permission_type'][0]['permission'][1].hasPermission 
					? <a className='btn btn-entity btn-sm ml-1' href={"tel:"+value.phone+""} title={translations[session.language]['entity']['panel']['body']['table'].buttons.phone}><i className="fa fa-phone"></i></a>
					: '')}
					{(session.permissions[3]['permission_type'][0]['permission'][1].hasPermission 
					? <a className='btn btn-entity btn-sm ml-1' href={"mailto:"+value.email+"?subject=CIG - Gestão de Vagas | Pedido de Contacto/Acolhimento&body="} title={translations[session.language]['entity']['panel']['body']['table'].buttons.email}><i className="fa fa-paper-plane"></i></a>
					: '')}
				</center>
			)
		}
	];

	const tableColumnsAgregado = [
		{
			Header: "Nome",
			accessor: "name"
		},
		{
			Header: "D.N",
			accessor: "dt"
		},
		{
			Header: "Parentesco",
			accessor: "rel"
		},
		{
			Header: "Profissão/Ocupação",
			accessor: "prof"
		},
		{
			Header: "Estabelecimento Ensino/Ano",
			accessor: "esc"
		},
		{
			id: 'button',
			Header: "Opções",
			accessor: 'id',
			width: 200,
			Cell: ({value}) => (
				<center>
					<button className='btn btn-danger btn-sm ml-1' onClick={() => remAgregado(value,1)} title="Remover Linha"><i className="fa fa-trash"></i></button>
				</center>
			)
		}
	];
	const tableColumnsAgressor = [
		{
			Header: "Nome",
			accessor: "name"
		},
		{
			Header: "D.N",
			accessor: "dt"
		},
		{
			Header: "Parentesco",
			accessor: "rel"
		},
		{
			Header: "Profissão/Ocupação",
			accessor: "prof"
		},
		{
			id: 'button',
			Header: "Opções",
			accessor: 'id',
			width: 200,
			Cell: ({value}) => (
				<center>
					<button className='btn btn-danger btn-sm ml-1' onClick={() => remAgregado(value,2)} title="Remover Linha"><i className="fa fa-trash"></i></button>
				</center>
			)
		}
	];
	
	useEffect(() => {
		loadData();
		loadSubData(session.idinst);
		(async () => {
			try {
				// await api.get('/inst-district')
				// .then(response => {
				// 	setInstDistrict(response.data);
				// });
				// await api.get('/inst-city')
				// .then(response => {
				// 	setInstCity(response.data);
				// });
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		})();
	}, []);

	// useEffect(() => {
	// 	if(isMain){
	// 		loadSubData(id);
	// 	}
	// }, [isMain])

	async function loadData() {
		setReload(true);
		//loadSubData(id);
		try {
			await api.get('/entity')
			.then(response => {
				
				let vagas = response.data;
				let ca = 0;
				let ce = 0;
				let ca_ra = 0;
				for(let i = 0;i < vagas.length;i++){
					const dt = vagas[i]['lastdt'].split("T");
					vagas[i]['lastdt'] = dt[0];
					vagas[i]['extra'] = { id: vagas[i].id,showinfo: vagas[i].showinfo,email: vagas[i].email,name: vagas[i].name,phone: vagas[i].phone}
					if(vagas[i]['iddistrict']<=18){
						ca += vagas[i].lastvacancya;
						ce += vagas[i].lastvacancye;
					}else{
						ca_ra += vagas[i].lastvacancya;
					}
					
				}
				setCurrentVacancyA(ca);
				setCurrentVacancyA_Ra(ca_ra);
				setCurrentVacancyE(ce);
				setInsts(vagas);
				setInstsFiltered(vagas);
				setIsMain(true);
			});
			await api.get('/users')
			.then(response => {
				// const util = response.data;
				// let newutil = [];
				// for(let i = 0;i < util.length;i++){
				// 	if(util[i]['id_core_user_type'] === 3){
				// 		newutil[newutil.length] = util[i];
				// 	}
				// }
				// setUsers(newutil);
			});
			setReload(false);
			let pesquisa = document.getElementById('search').value;
			let filter;
			if(pesquisa){
				let results = [];
				if (pesquisa.indexOf(' ') !== -1) {
					filter = pesquisa.split(' ');
					filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
					for(let a = 0;a < insts.length;a++){
						for(let b = 0;b < filter.length;b++){
							if(insts[a].name.toLowerCase().includes(filter[b]) || insts[a].district.toLowerCase().includes(filter[b]) || insts[a].lastdt.toLowerCase().includes(filter[b])){
								results[results.length] = insts[a];
								break;
							}
						}
					}
				}else{
					for(let a = 0;a < insts.length;a++){
						if(insts[a].name.toLowerCase().includes(pesquisa) || insts[a].district.toLowerCase().includes(pesquisa) || insts[a].lastdt.toLowerCase().includes(pesquisa)){
							results[results.length] = insts[a];
						}
					}
				}
				setInstsFiltered(results);
			}
		} catch (err) {
			setErrorMessage(err.message);
			setSweetAlertError(true);
		}
	}
	const [req , setReq] = useState([]);
	const [tipoReq, setTipoReq] = useState(1);
	const [vagasReq, setVagasReq] = useState(0);
	const [currentInsts, setCurrentInsts] = useState([]);
	function loadSubReq(){
		setIsMain(false);
		const newReq = {
			id: 0,
			iduser: session.id,
			nameEnc: session.inst.name,
			phoneEnc: session.inst.phone,
			emailEnc: session.inst.email,
			insts: [],
			tipo: 0,
			vagas: 1,
			aco: [],
			agregado: [],
			agressor: [],
			espec: [],
			sumario: [],
			data: new Date().toISOString().split('T')[0],
			meio: ""

		}
		setReq(newReq);
		setTipoReq(1);
		setVagasReq(1);
		setSelectedInsts([]);
		let currentI = [];
		currentI[0] = {id: 0, name: "Selecione a Entidade de Acolhimento."};
		const keyCap = tipoReq === 1 ? "lastvacancya" : "lastvacancye"; 
		for(let i = 0;i < insts.length;i++){
			if(parseInt(insts[i][keyCap]) !== 0 && parseInt(insts[i][keyCap]) >= parseInt(vagasReq)){
				currentI[currentI.length] = insts[i];
			}
		}
		if(currentI.length === 1){
			currentI = [];
			currentI[0] = {id: 0, name: "Sem Entidades Válidas."};
		}
		setCurrentInsts(currentI);
		const dt = newReq.data.split("-");
		setTimeout(function() {
			document.getElementById('nameEnc').innerHTML = newReq.nameEnc;
			document.getElementById('contEnc').innerHTML = newReq.emailEnc + " / "+ newReq.phoneEnc;
			document.getElementById('dataFunc').innerHTML = dt[2] + " - " + dt[1] + " - " + dt[0].substring(2, 4);
		},1000);
		setAgregado([]);
		setAgressor([]);
	}
	
	function addLineAgregado(esc){
		if(esc === 1){
			let newAgregado = agregado;
			newAgregado[newAgregado.length] = {
				id: newAgregado.length,
				name: document.getElementById('nameAgre').value,
				dt: document.getElementById('dtAgre').value,
				rel: document.getElementById('relAgre').value,
				prof: document.getElementById('profAgre').value,
				esc: document.getElementById('escAgre').value
			}
			document.getElementById('nameAgre').value = "";
			document.getElementById('dtAgre').value = "";
			document.getElementById('relAgre').value = "";
			document.getElementById('profAgre').value = "";
			document.getElementById('escAgre').value = "";
			setAgregado(newAgregado);
			//scrollToTargetAdjusted("tabagregado");
		}else{
			let newAgressor = agressor;
			newAgressor[newAgressor.length] = {
				id: newAgressor.length,
				name: document.getElementById('nameAgressor').value,
				dt: document.getElementById('dtAgressor').value,
				rel: document.getElementById('relAgressor').value,
				prof: document.getElementById('profAgressor').value
			}
			document.getElementById('nameAgressor').value = "";
			document.getElementById('dtAgressor').value = "";
			document.getElementById('relAgressor').value = "";
			document.getElementById('profAgressor').value = "";
			setAgressor(newAgressor);
			//scrollToTargetAdjusted("tabagressor");
		}
	}

	function remAgregado(indice, esc){
		if(esc === 1){
			let newAgregado = agregado;
			newAgregado.splice(indice,1);
			for(let i = 0;i < newAgregado.length;i++){
				newAgregado[i].id = i;
			}
			setAgregado(newAgregado);
			//scrollToTargetAdjusted("tabagregado");
		}else{
			let newAgressor = agressor;
			newAgressor.splice(indice,1);
			for(let i = 0;i < newAgressor.length;i++){
				newAgressor[i].id = i;
			}
			setAgressor(newAgressor);
			//scrollToTargetAdjusted("tabagressor");
		}
	}
	function scrollToTargetAdjusted(el){
		var element = document.getElementById(el);
		var headerOffset = -10;
		var elementPosition = element.getBoundingClientRect().top;
		var offsetPosition = elementPosition - headerOffset;
	
		window.scrollTo({
			 top: offsetPosition,
			 behavior: "smooth"
		});
	}
	function handleSelectInsts(e){
		let selinst = selectedInsts;
		if(!selinst.find(x => x.id === parseInt(e.target.value)) && parseInt(e.target.value) !== 0){
			selinst[selinst.length] = {id: parseInt(e.target.value), label: e.target.options[e.target.selectedIndex].text,name: e.target.options[e.target.selectedIndex].text, cont: insts.find(x => x.id === parseInt(e.target.value)).email + " / " +insts.find(x => x.id === parseInt(e.target.value)).phone,email: insts.find(x => x.id === parseInt(e.target.value)).email}
			setSelectedInsts(selinst);
			scrollToTargetAdjusted("teste");
			document.getElementById('teste').value = 0;
		}else{
			document.getElementById('teste').value = 0;
		}
		
	}
	function remLineSelectInsts(indice){
		
		let newSelectedInsts = selectedInsts;
		newSelectedInsts.splice(newSelectedInsts.findIndex( s => s.id === indice ),1);
		for(let i = 0;i < newSelectedInsts.length;i++){
			newSelectedInsts[i].id = i;
		}
		setSelectedInsts(newSelectedInsts);
		scrollToTargetAdjusted("teste");
	}
	async function handleNewReq(e) {
		e.preventDefault();
		let val = 0;
		let msg = "";
		let nameUte = document.getElementById('nameUte').value;
		if(!nameUte){
			msg += "O campo do nome do utente encontra-se vazio ou inválido.";
			val++;
		}
		let addressUte = document.getElementById('addressUte').value;
		if(!addressUte){
			msg += "O campo da morada do utente encontra-se vazio ou inválido.";
			val++;
		}
		let phoneUte = document.getElementById('phoneUte').value;
		if(!phoneUte){
			msg += "O campo do contacto do utente encontra-se vazio ou inválido.";
			val++;
		}
		let birthdayUte = document.getElementById('birthdayUte').value;
		if(!birthdayUte){
			msg += "O campo data de Nascimento do utente encontra-se vazio ou inválido.";
			val++;
		}
		if(agressor.length === 0){
			msg += "A tabela do agressor não pode ir vazia.";
			val++;
		}
		const vagas = parseInt(document.getElementById('vagasReq').value);
		if(vagas && vagas >= 1){
			let vagasTemp = parseInt(vagas)-1;
			if(parseInt(agregado.length) < parseInt(vagasTemp)){
				msg += "Encontram-se vagas por preencher, por favor verifique.";
				val++;
			}else if(parseInt(agregado.length) > parseInt(vagasTemp)){
				msg += "Encontram-se vagas preenchidas a mais, por favor verifique.";
				val++;
			}
		}else{
			msg += "O campo do nº de vagas encontra-se vazio ou inválido.";
			val++;
		}
		const tipoC = document.getElementById('tipoC1');
		let tipo = 0;
		if (tipoC.checked === true) {
			tipo = 1;
		} else {
			tipo = 2;
		}
		if(selectedInsts.length === 0){
			msg += "É necessário pelo menos selecionar uma instituição de acolhimento.";
			val++;
		}
		let meio = 'enviado por mail';

		let espec = [];
		for(let i = 0;i < 6;i++){
			const simnao = document.getElementById('simnao'+i);
			if (simnao.checked === true) {
				espec[espec.length] = {simnao: "Sim",ele: document.getElementById('ele'+i).value, esp: document.getElementById('esp'+i).value};
			} else {
				espec[espec.length] = {simnao: "Não",ele: "", esp: ""};
			}
		}
		
		if (val !== 0) {
			console.log("Erro:" + msg);
			setErrorMessage(msg);
			setSweetAlertError(true);
		} else {
			let sendInsts = [];
			for(let i = 0;i < selectedInsts.length;i++){
				sendInsts[sendInsts.length] = selectedInsts[i].id;
			}
			const newReq = {
				...req,
				utente: {
					name: nameUte,
					address: addressUte,
					phone: phoneUte,
					birthday: birthdayUte
				},
				agregado: agregado,
				agressor: agressor,
				aco: selectedInsts,
				espec: espec,
				sumario: document.getElementById('sumario').value,
				meio: meio,
				insts: sendInsts,
				tipo: tipo,
				vagas: vagas
			}
			try {
				await api.post('/entity', newReq)
				.then(response => {
					setModalDialog(false);
					setSuccessMessage(translations[session.language]['entity']['responses']['create'][response.data.message]);
					setUseId(response.data.id);
					setReq(newReq);
					infoPdf(response.data.id)
					setSweetAlertSuccess(true);
					loadData();
				})
				.catch(err => {
					setErrorMessage(translations[session.language]['entity']['responses']['create'][err.response.data.message]);
					setSweetAlertError(true);
				})
			} catch (err) {
				setErrorMessage(err.message);
				setSweetAlertError(true);
			}
		}
	}
	function handleClickcheckbox(esc){
		if(document.getElementById('simnao'+esc).checked){
			document.getElementById("ele"+esc).removeAttribute("disabled"); 
			document.getElementById("esp"+esc).removeAttribute("disabled");
		}else{
			document.getElementById("ele"+esc).setAttribute("disabled","true"); 
			document.getElementById("esp"+esc).setAttribute("disabled","true");  
		}
	}

	// BUSCAR INFO PDF
    async function infoPdf(id){
        try {
            await api.get(`pdf/${id}`)
            .then(response => {
				let newdata = response.data;
				let newinfo = JSON.parse(newdata.info);
				console.log(newinfo);
				setReq(newinfo);
				setUseId(id);
				setPagePdf(newinfo);
				setTimeout(function(){eventFire(document.getElementById('autoDownload'), 'click', 'autoDownload');},2000);
            })
            .catch(err => {
                console.log("error: "+err);
            })
        } catch (err) {
            console.log("error: "+err);
        }
	}
	
	function handleTipoChange(e) {
		if(e.target.type === 'text' && !e.target.value){
			setVagasReq('');
		}else{
			const inputType = e.target.type;
			let newTipoReq = tipoReq;
			let newVagasReq = vagasReq;
			if(inputType === "radio"){
				setTipoReq(parseInt(e.target.value));
				newTipoReq = parseInt(e.target.value);
			}else{
				setVagasReq(parseInt(e.target.value));
				newVagasReq = parseInt(e.target.value);
			}
			
			setSelectedInsts([]);
			let currentI = [];
			currentI[0] = {id: 0, name: "Selecione a Entidade de Acolhimento."};
			const keyCap = newTipoReq === 1 ? "lastvacancya" : "lastvacancye"; 
			for(let i = 0;i < insts.length;i++){
				if(parseInt(insts[i][keyCap]) !== 0 && parseInt(insts[i][keyCap]) >= parseInt(newVagasReq)){
					currentI[currentI.length] = insts[i];
				}
			}
			if(currentI.length === 1){
				currentI = [];
				currentI[currentI.length] = {id: 0, name: "Sem Entidades Válidas."};
			}
			setCurrentInsts(currentI);
		}
	}
	function handleFilter(e){
		const pesquisa = e.target.value.toLowerCase();
		let filter;
		if(pesquisa === ''){
			setInstsFiltered(insts);
		}else{
			let results = [];
			if (pesquisa.indexOf(' ') !== -1) {
				filter = pesquisa.split(' ');
				filter.indexOf("") !== -1 && filter.splice(filter.indexOf(""),1);
				for(let a = 0;a < insts.length;a++){
					for(let b = 0;b < filter.length;b++){
						if(insts[a].name.toLowerCase().includes(filter[b]) || insts[a].district.toLowerCase().includes(filter[b]) || insts[a].lastdt.toLowerCase().includes(filter[b])){
							results[results.length] = insts[a];
							break;
						}
					}
				}
			}else{
				for(let a = 0;a < insts.length;a++){
					if(insts[a].name.toLowerCase().includes(pesquisa) || insts[a].district.toLowerCase().includes(pesquisa) || insts[a].lastdt.toLowerCase().includes(pesquisa)){
						results[results.length] = insts[a];
					}
				}
			}
			setInstsFiltered(results);
		}
	}
	let conteudo = "";
	let mapa = "";

	if(isMain){
		var myIconA = new L.Icon({
			iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
			shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});
		var myIconE = new L.Icon({
			iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
			shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});
		var myIconB = new L.Icon({
			iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
			shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});
		const position = [39.553462,-8.0372837];
		conteudo = <div className="panel-body row"><div className='col-md-12'>
				<div className="form-group row m-b-15 pull-right">
					<label className="col-form-label col-md-3">Procurar:</label>
					<div className="col-md-9">
						<input type="text" id="search" className="form-control" onChange={(e) => handleFilter(e)}/>
					</div>
				</div>
			</div>
			<div className='col-md-12'><ReactTable data={instsFiltered} columns={tableColumns} defaultPageSize={10} defaultSorted={defaultSorted} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'}/>
			{(reload && 
				<div className="panel-loader">
					<span className="spinner-small"></span>
				</div>
			)}
			</div>
		</div>;
		const pontos = insts.map((inst) => inst.latitude ?
			<Marker key={inst.id} position={[inst.latitude,inst.longitude]}  icon={inst.lastvacancye !== 0 ? (inst.lastvacancya !== 0 ? (myIconB) : (myIconE)) : (myIconA)}>
				<Popup>
					<b>{inst.name}</b>{inst.lastvacancya !== 0 ? <span><br /><b>Vagas (CA): </b> {inst.lastvacancya}</span> : '' }{inst.lastvacancye !== 0 ? <span><br /><b>Vagas (CE): </b> {inst.lastvacancye}</span> : '' }
				</Popup>
			</Marker>
			: ''
		);
		mapa =	<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
			<div className="panel-heading">
				<h4 className="panel-title">Mapa de Instituições</h4>
				<div className="panel-heading-btn">
					<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>
				</div>
			</div>
			<div className="panel-body">
			<div className='leaflet-container'>
				
				<Map center={position} zoom={7}>
					<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{pontos}
				</Map>
				</div>
			</div>
		</div> ;
	}else{
		conteudo = <div className="panel-body"><form onSubmit={handleNewReq}>
			<div className='row'>
				<div className='col-md-12 row'>
					<div className='col-md-12'><h5>Entidade Encaminhadora</h5></div>
					<div className='col-md-6'>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Instituição</label>
							<div className="col-md-9">
								<p id="nameEnc" className="form-control" />
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Email/Contacto</label>
							<div className="col-md-9">
								<p id="contEnc" className="form-control" />
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-12 row'>
				<div className='col-md-12'><h5>Entidade de Acolhimento</h5></div>
					<div className='col-md-6'>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Tipologia</label>
							<div className="col-md-4 row">
								<div className='col-md-3'>
									<input type="radio" name="tipoC" id="tipoC1" className="form-control" value="1" onChange={(e) => handleTipoChange(e)} checked={ tipoReq === 1 ? true : false} style={{fontSize:"3px",marginTop:"5px"}}/>
								</div>
								<label className="col-form-label col-md-"> 
									Casa Abrigo
								</label>
							</div>
							<div className="col-md-4 row">
								<div className='col-md-3'>
									<input type="radio" name="tipoC" id="tipoC2" className="form-control" value="2" onChange={(e) => handleTipoChange(e)} checked={ tipoReq === 2 ? true : false} style={{fontSize:"3px",marginTop:"5px"}}/>
								</div>
								<label className="col-form-label col-md-9"> 
									Casa Emergência
								</label>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Nº de Vagas Solicitadas</label>
							<div className="col-md-9">
								<input type="text" id="vagasReq" className="form-control" value={vagasReq} onChange={(e) => handleTipoChange(e)}/>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Disponíveis</label>
							<div className="col-md-9">
								<select id="teste" className="form-control" onChange={(e) => handleSelectInsts(e)}>
								{
									currentInsts.map(currentInst => (
										<option key={currentInst.id} value={currentInst.id}>{currentInst.name}</option>
									))
								}
								</select>
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Data</label>
							<div className="col-md-9">
								<p type="text" id="dataFunc" className="form-control" />
							</div>
							
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">(Meio de envio de pedido)</label>
							<div className="col-md-9">
								<p type="text" id="meio" className="form-control">enviado por mail</p>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-12">Instituições de Acolhimento</label>
							<div className="col-md-12">
								
								{
									selectedInsts.map(selectedInst => (
										<p className="form-control" key={selectedInst.id} style={{borderBottom:"1px solid black"}}><span className="pull-left">{selectedInst.label}</span><button type='button' className='btn btn-white btn-xs pull-right' onClick={() => remLineSelectInsts(selectedInst.id)} title="Remover Instituição."><i className="fa fa-times"></i></button></p>
									))
								}
								
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-12' style={{borderTop: "1px solid grey",paddingBottom:"10px"}}>
					<h5 style={{paddingTop:"10px"}}>Identificação da/o Utente</h5>
					<div className='row'>
						<div className='col-md-3'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Nome</label>
								<div className="col-md-12">
									<input type="text" id="nameUte" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-3'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Morada</label>
								<div className="col-md-12">
									<input type="text" id="addressUte" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-3'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Contacto</label>
								<div className="col-md-11">
									<input type="text" id="phoneUte" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-3'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Data Nascimento</label>
								<div className="col-md-12">
									<input type="date" id="birthdayUte" className="form-control" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12" style={{borderTop: "1px solid grey",paddingBottom:"10px"}}  id='tabagregado'>
					<h5 style={{paddingTop:"10px"}}>Composição Agregado Familiar que acompanha a/o utente</h5>
					<ReactTable data={agregado} columns={tableColumnsAgregado} defaultPageSize={5} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'}/>
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
					<div className='row'>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Nome</label>
								<div className="col-md-12">
									<input type="text" id="nameAgre" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">D.N.</label>
								<div className="col-md-12">
									<input type="date" id="dtAgre" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Parentesco</label>
								<div className="col-md-11">
									<input type="text" id="relAgre" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Profissão/Ocupação</label>
								<div className="col-md-11">
									<input type="text" id="profAgre" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-3'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Estabelecimento Ensino/Ano</label>
								<div className="col-md-11">
									<input type="text" id="escAgre" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-1'>
							<br />
							<br />
							<button type='button' className='btn btn-success btn-sm ml-1 pull-left' onClick={() => addLineAgregado(1)} title="Adicionar Parente"><i className="fa fa-plus"></i></button>
						</div>
					</div>
				</div>

				<div className='col-md-12' style={{borderTop: "1px solid grey",paddingBottom:"10px"}} id="tabagressor">
					<h5 style={{paddingTop:"10px"}}>Identificação da pessoa que praticou o ato violento</h5>
					<ReactTable data={agressor} columns={tableColumnsAgressor} defaultPageSize={5} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'}/>
					{(reload && 
						<div className="panel-loader">
							<span className="spinner-small"></span>
						</div>
					)}
					<div className='row'>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Nome</label>
								<div className="col-md-12">
									<input type="text" id="nameAgressor" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">D.N.</label>
								<div className="col-md-12">
									<input type="date" id="dtAgressor" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Parentesco</label>
								<div className="col-md-11">
									<input type="text" id="relAgressor" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-2'>
							<div className="form-group row m-b-15">
								<label className="col-form-label col-md-12">Profissão/Ocupação</label>
								<div className="col-md-11">
									<input type="text" id="profAgressor" className="form-control" />
								</div>
							</div>
						</div>
						<div className='col-md-3'>&nbsp;</div>
						<div className='col-md-1'>
							<br /><br />
							<button type='button' className='btn btn-success btn-sm ml-1 pull-left' onClick={() => addLineAgregado(2)} title="Adicionar Agressor"><i className="fa fa-plus"></i> </button>
						</div>
					</div>
				</div>
				<div className='col-md-12' style={{borderTop: "1px solid grey",paddingBottom:"10px"}}>
					<h5 style={{paddingTop:"10px"}}>Informações Adicionais (relativas ao agregado a acolher)</h5>
					<div className='row'>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">Necessidades Especificas de Saúde</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao0" className="form-control" value="Sim" onClick={() => handleClickcheckbox(0)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele0" className="form-control" placeholder="Indique o Elemento" disabled />
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp0" className="form-control" placeholder="Especifique" disabled />
								</div>
							</div>
						</div>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">Necessidades Educativa Especiais</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao1" className="form-control" value="Sim" onClick={() => handleClickcheckbox(1)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele1" className="form-control" placeholder="Indique o Elemento" disabled />
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp1" className="form-control" placeholder="Especifique" disabled />
								</div>
							</div>
						</div>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">Frequência Escolar (menores)</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao2" className="form-control" value="Sim" onClick={() => handleClickcheckbox(2)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele2" className="form-control" placeholder="Indique o Elemento" disabled />
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp2" className="form-control" placeholder="Especifique" disabled />
								</div>
							</div>
						</div>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">CPCJ</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao3" className="form-control" value="Sim" onClick={() => handleClickcheckbox(3)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele3" className="form-control" placeholder="Indique o Elemento" disabled />
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp3" className="form-control" placeholder="Especifique" disabled />
								</div>
							</div>
						</div>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">Denúncia pelo Crime de VD</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao5" className="form-control" value="Sim" onClick={() => handleClickcheckbox(5)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele5" className="form-control" placeholder="Indique o Elemento" disabled/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp5" className="form-control" placeholder="Especifique"  disabled/>
								</div>
							</div>
						</div>
						<div className='col-md-12 row' style={{paddingTop:"3px",paddingBottom:"3px"}}>
							<div className='col-md-4'>
								<label className="col-form-label col-md-12">Outros Processos (crime, civel, Promoçao e Proteção, Tutelar Educativo, etc)</label>
							</div>
							<div className='col-md-2'>
								<div className="form-group row m-b-15">
									<label className="col-form-label col-md-3">Sim?</label>
									<div className="col-md-3">
										<input type="checkbox" id="simnao4" className="form-control" value="Sim" onClick={() => handleClickcheckbox(4)}/>
									</div>
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="ele4" className="form-control" placeholder="Indique o Elemento" disabled />
								</div>
							</div>
							<div className='col-md-3'>
								<div className="col-md-12">
									<input type="text" id="esp4" className="form-control" placeholder="Especifique" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-12' style={{borderTop: "1px solid grey",paddingBottom:"10px"}}>
					<h5 style={{paddingTop:"10px"}}>Diagnóstico Sumário</h5>
					<div className="form-group row m-b-15">
						<div className="col-md-12">
							<textarea id="sumario" className="form-control" />
							<small>Nota: apenas em casos de resposta imediata para vaga de Emergência, sendo de remeter posteriormente informação recolhida</small>
						</div>
					</div>
				</div>
				<div className='col-md-12' style={{borderTop: "1px solid grey",paddingBottom:"10px"}}>
					<button type="submit" className='btn btn-success pull-right'><i className='fa fa-file-pdf'></i> Gerar</button>
				</div>
			</div>
		</form></div>
	}
	return (
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active">{translations[session.language]['entity'].title}</li>
			</ol>
			<h1 className="page-header">{translations[session.language]['entity'].title}</h1>
			{ isMain ?
			<div className="row">
				<div className="col-xl-3 col-md-6">
					<div className="widget widget-stats" style={{
						margin: '0px 15px 20px 0px',
						backgroundColor: '#0099ff',
						padding:'5px 5px'
					}}>
						<div className="stats-icon stats-icon-lg"><i className="fa fa-th fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title" style={{fontSize: "14px",fontWeight: "bold"}}>Total Vagas Casa Abrigo<br />Continente</div>
							<div className="stats-number">&nbsp;&nbsp;{currentVacancyA}</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6">
					<div className="widget widget-stats" style={{
						margin: '0px 15px 20px 0px',
						backgroundColor: '#ff0000',
						padding:'5px 5px'
					}}>
						<div className="stats-icon stats-icon-lg"><i className="fa fa-ambulance fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title" style={{fontSize: "14px",fontWeight: "bold"}}>Total Vagas Casa Emergência<br />Continente</div>
							<div className="stats-number">&nbsp;&nbsp;{currentVacancyE}</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6">
					<div className="widget widget-stats" style={{
						margin: '0px 15px 20px 0px',
						backgroundColor: '#0099ff',
						padding:'5px 5px'
					}}>
						<div className="stats-icon stats-icon-lg"><i className="fa fa-th fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title" style={{fontSize: "14px",fontWeight: "bold"}}>Total Vagas Casa Abrigo<br />Regiões Autónomas</div>
							<div className="stats-number">&nbsp;&nbsp;{currentVacancyA_Ra}</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6" style={{position: "relative"}}>
					<button className="btn btn-success" onClick={() => loadSubReq()} style={{position: "absolute",bottom: "20px",right: "10px"}}><i className="fa fa-file-pdf"></i> Novo Encaminhamento</button>
				</div>
			</div>
			
			 : '' }
			{
				(isMain && (type === 4 || type === 3)) ?
				<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
					<div className="panel-heading">
						<h4 className="panel-title">Listagem de Pedidos Efetuados</h4>
						<div className="panel-heading-btn">
							{/* {type === 3 ? '' : <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => loadData()}><i className="fa fa-reply"></i></button>} */}
							
							&nbsp;&nbsp;<button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => type === 3 ? loadData() : loadSubData(session.idinst)}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
						</div>
					</div>
					<div className="panel-body">
						<ReactTable data={forwardings} columns={tableColumnsForwarding} defaultPageSize={5} defaultSorted={defaultSortedDesc} className="-highlight" previousText={'Anterior'} nextText={'Seguinte'} loadingText={'Em atualização...'} noDataText={'Sem dados'} pageText={'Página'} ofText={'de'} rowsText={'linhas'} />
						{(reload && 
							<div className="panel-loader">
								<span className="spinner-small"></span>
							</div>
						)}
					</div>
				</div>
				:
				''
			}
			 
			<div className={'panel panel-inverse ' + (expand ? 'panel-expand ' : ' ') + (reload ? 'panel-loading ' : ' ')}>
			<div className="panel-heading">
				<h4 className="panel-title">{ isMain ? translations[session.language]['entity']['panel'].title : translations[session.language]['entity']['panel'].title2}</h4>
				<div className="panel-heading-btn">
					{ isMain ? <button className="btn btn-xs btn-icon btn-circle btn-primary" onClick={() => loadData()}><i className="fa fa-redo"></i></button> : <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={() => loadData()}><i className="fa fa-reply"></i></button> }
					&nbsp;&nbsp;
					<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>
				</div>
			</div>
			
			{conteudo}
			
		
			
		</div>
		{mapa}
		<div style={{width:"100%"}}>
			{<Download />}
		</div>
			<Modal isOpen={modalDialog} toggle={() => toggleModal(0)} >
				<ModalHeader toggle={() => toggleModal(0)}>
					{translations[session.language]['entity']['modal']['header'].see}
				</ModalHeader>
				<form>
					<ModalBody>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Nome</label>
							<div className="col-md-9">
								<p type="text" id="nameInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Email</label>
							<div className="col-md-9">
								<p type="text" id="emailInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Contacto</label>
							<div className="col-md-9">
								<p type="text" id="phoneInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Morada</label>
							<div className="col-md-9">
								<p type="text" id="addressInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Código Postal</label>
							<div className="col-md-9">
								<p type="text" id="postalcodeInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Cidade</label>
							<div className="col-md-9">
								<p type="text" id="cityInst" className="form-control" />
							</div>
						</div>
						<div className="form-group row m-b-15">
							<label className="col-form-label col-md-3">Distrito</label>
							<div className="col-md-9">
								<p type="text" id="districtInst" className="form-control" />
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-white mr-auto" onClick={() => toggleModal(0)}>{translations[session.language]['entity']['modal']['footer'].close}</button>
					</ModalFooter>
				</form>
			</Modal>
			{(sweetAlertSuccess &&
				<SweetAlert success
					confirmBtnText="OK"
					confirmBtnBsStyle="success"
					title={translations[session.language]['alerts']['success'].title}
					onConfirm={() => setSweetAlertSuccess(false)}
				>
					{successMessage}
				</SweetAlert>
			)}
			{(sweetAlertWarning &&
					<SweetAlert warning showCancel
						confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
						cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="default"
						title={translations[session.language]['alerts']['warning'].title}
						onConfirm={() => {setSweetAlertWarning(false)}}
						onCancel={() => setSweetAlertWarning(false)}
					>
						{translations[session.language]['entity']['alerts']['warning'].message}
					</SweetAlert>
				)}
			{(sweetAlertError &&
					<SweetAlert danger
							confirmBtnText="OK"
							confirmBtnBsStyle="default"
							title={translations[session.language]['alerts']['error'].title}
							onConfirm={() => setSweetAlertError(false)}
					>
						{errorMessage}
					</SweetAlert>
			)}
			{(sweetAlertWarningForwardingRefuse[0] &&
				<SweetAlert warning showCancel
					confirmBtnText={translations[session.language]['alerts']['warning'].confirmBtn}
					cancelBtnText={translations[session.language]['alerts']['warning'].cancelBtn}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title={translations[session.language]['alerts']['warning'].title}
					onConfirm={() => {handleRefuseForwarding(sweetAlertWarningForwardingRefuse[1]);setSweetAlertWarningForwardingRefuse([false, 0])}}
					onCancel={() => setSweetAlertWarningForwardingRefuse([false, 0])}
				>
					{translations[session.language]['insts']['alerts']['warning'].message4}
					<div className="form-group row m-b-15">
						<label className="col-form-label col-md-12" style={{fontWeight: 'bold'}}>Observações</label>
						<div className="col-md-12">
							<textarea className="form-control" value={observationRefuse} onChange={(e) => setObservationRefuse(e.target.value)}/>
						</div>
					</div>
				</SweetAlert>
			)}
		</div>
	)
}